.table {
    max-width: 1400px;
}

.table span+:global(.ant-tag) {
    margin-left: 4px;
}

.table :global(.ant-tag) {
    font-size: 85%;
    padding: 0 4px;
    vertical-align: top;
}

.list-item :global(.ant-card) {
    width: 100%;
}

.list-item {
    padding: 6px 0;
}

.list-item :global(.ant-card-head-title) {
    font-weight: bold;
    font-size: 14px;
}

.list-item :global(.ant-descriptions-item-label) {
    font-weight: bold;
}

.list-item th, .list-item td {
    padding-bottom: 8px;
}