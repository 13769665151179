/* open-sans-regular - latin_latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/open-sans-v16-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('fonts/open-sans-v16-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v16-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v16-latin_latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin_latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/open-sans-v16-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('fonts/open-sans-v16-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('fonts/open-sans-v16-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */ url('fonts/open-sans-v16-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('fonts/open-sans-v16-latin_latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

h1 {
    font-size: 2em;
}

.common__breadcrumb {
    margin-bottom: 1em;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ant-form-item {
    margin-bottom: 12px;
}

.ant-form-item .ant-form-item {
    margin-bottom: 0;
}

.ant-form-item-has-error .ant-input {
    background: #fff;
    border-color: #ff4d4f;
}

.localized-name .ant-input-group-addon {
    width: 40px;
}

/* Filter form */

.common__filter {
    margin-bottom: 1.5em;
}

.common__filter .ant-row {
    width: 100%;
}

.common__filter .ant-form-item {
    margin-bottom: 1em;
}

.detail-view h2 {
    margin: 1.5em 0 0.5em 0;
}

.collapse-css-transition h2 {
    margin: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    padding-top: 1px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    user-select: none;
    -ms-user-select: none;
}

.mass-action-button span.count {
    text-transform: none;
    padding-left: 5px;
    color: #A7AABD;
    font-weight: normal;
}

.more-actions span {
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    color: black;
    opacity: 0.8;
}

.more-actions span.menu-item-text {
    margin-left: 8px;
}

.ant-table-selection-column .ant-checkbox {
    margin: 0 0.5em;
}

.ant-table-selection-column .ant-checkbox-inner {
    width: 16px;
    height: 16px;
}

.ant-table-selection-column .ant-checkbox-inner::after {
    width: 5.714285713333333px;
    height: 9.14285714px;
}

.ant-table-selection-column .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    width: 8.57142857px;
    height: 9.14285714px;
}

.ant-checkbox-wrapper {
    align-items: flex-end;
}

.ant-layout-content > div {
    padding: 0 0 50px !important;
}

ul.ant-menu.admin-menu {
    color: #434656;
    display: inline-block;
    border-bottom: 0;
    font-size: 14px;
    margin-top: 0px;
    letter-spacing: 0.5px;
    line-height: 30px;
    height: 72px;
}

.admin-menu li.ant-menu-item-only-child {
    padding: 0 !important;
}

ul.ant-menu.admin-menu .anticon {
    margin-right: 8px;
}

ul.ant-menu.admin-menu .anticon, .admin-menu .anticon {
    font-size: 14px;
}

ul.ant-menu.admin-menu .anticon svg path, .admin-menu .anticon svg path {
    fill: #434656;
}

ul.ant-menu.admin-menu .ant-menu-submenu-active, ul.ant-menu .admin-menu.ant-menu-submenu-open, ul.ant-menu.admin-menu .ant-menu-submenu-selected {
    border-color: transparent;
}

ul.ant-menu.admin-menu .ant-menu-horizontal > .ant-menu-submenu:hover {
    border-color: transparent;
    color: #434656;
}

.admin-menu .ant-menu-submenu-selected {
    color: #434656;
}

.ant-menu.ant-menu-sub.ant-menu-vertical {
    margin-top: 0;
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item {
    color: #434656;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding-bottom: 8px;
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item-active {
    background: rgba(5, 131, 255, 0.3);
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
    color: #434656;
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item svg path {
    fill: rgba(67, 70, 86, 0.5);
}

.ant-menu.ant-menu-sub.ant-menu-vertical .ant-menu-item:hover a {
    color: #434656;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.ant-menu-submenu, .ant-menu-item {
    font-weight: 600;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    margin: 0 !important;
}

ul.ant-menu {
    margin-top: 20px;
}

ul.ant-menu-sub {
    margin-top: 0;
}

.ant-alert-with-description {
    padding: 8px 15px;
}

.ant-card-body {
    padding-bottom: 8px;
}

.ant-breadcrumb-link h1 {
    display: inline;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
    border-right: 0px;
}

.ant-table-column-title {
    flex: none;
}

.ant-table-column-sorter {
    flex: 1;
    margin-left: 8px;
}

span.anticon.anticon-close.ant-tag-close-icon {
    font-size: 12px !important;
}

span.anticon.anticon-close {
    font-size: 14px !important;
}

.ant-select-clear {
    margin-top: -9px;
    right: 10px;
    width: 18px;
    height: 18px;
}

.color-orange {
    color: rgba(255, 128, 0, .5);
}

.ant-menu-submenu::after {
    border-bottom: none !important;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    z-index: auto;
}

.overlay-visible {
    display: block;
}

/* obecne */

.ant-btn {
    font-size: 14px;
    text-transform: initial;
    letter-spacing: .5px;
}

/* Zvetseni ikon celoplosne */
.ant-layout-content .anticon, button.ant-btn-icon-only {
    font-size: 18px !important;
}

.ant-input-number .ant-input-number-handler-up-inner, .ant-input-number .ant-input-number-handler-down-inner {
    font-size: 100% !important;
}

.ant-btn-lg {
    font-weight: 600 !important;
    height: 48px;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    padding-bottom: 2px;
}

/* obecne - konec */

/* floating strankovani */

.ant-spin-container .ant-table-pagination:first-child {
    display: none;
}

.ant-spin-container .ant-table-pagination:last-child {
    z-index: 500;
    position: fixed;
    bottom: 0px;
    right: 0px;
    background: #fff;
    padding: 10px;
    margin: 0;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

/* floating strankovani - konec */

h3 {
    font-size: 18px;
}

/* audit logy */

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: initial;
}

.ant-table-expanded-row {
    background: #E9EBF3;
}

.ant-table-expanded-row > td.ant-table-cell {
    background: transparent !important;
    padding-top: 0px !important;
    padding-left: 32px !important;
    padding-right: 0px !important;
    padding-bottom: 16px !important;
}

.ant-table-expanded-row > td.ant-table-cell > .ant-row {
    background: white;
    padding: 8px !important;
}

.ant-table-expanded-row > td.ant-table-cell .ant-table.ant-table-small.ant-table-bordered {
    background: #eeeeee !important;
}

.ant-table-expanded-row .ant-table.ant-table-small {
    background: initial;
}

.ant-table-expanded-row .ant-table.ant-table-small h4 {
    margin-bottom: 0px;
    text-align: center;
    font-weight: 600;
}

.ant-table-expanded-row .ant-table.ant-table-small .ant-table-thead th {
    background: #cccccc !important;
    color: black !important;
}

.ant-table-expanded-row .ant-table.ant-table-small .ant-table-row td {
    border-right: solid 1px white !important;
    border-bottom: solid 1px white !important;
}

/* logo */
#sonpo-logo {
    text-align: center;
    position: absolute;
    bottom: 24px;
    width: 200px;
    color: rgba(255, 255, 255, 0.65);
}

#sonpo-logo a {
    color: rgba(255, 255, 255, 0.65);
}

/* Oprava loga v menu */
@supports (-ms-ime-align:auto) {
    html, body {
        background: linear-gradient(90deg, #333343, 200px, #e9ebf3 200px);
    }

    .ant-layout-sider-children, .ant-layout-sider {
        min-height: 100% !important;
        height: 100% !important
    }

    .sofie-menu {
        min-height: calc(100% - 210px) !important;
    }

    #sonpo-logo {
        position: static !important;
        padding-bottom: 24px !important;
    }
}

.ant-table-row-expand-icon {
    transform: scale(1.2);
}

@media (max-width: 1224px) {
    .ant-card, .ant-descriptions {
        border-radius: 3px;
    }

    .ant-layout-content > div {
        padding: 0 !important;
    }

    .ant-layout-footer {
        padding: 8px 50px;
        background-color: white;
    }
}
