.ant-menu-submenu.ant-menu-submenu-popup ul {
    color: #434656;
}

.ant-menu-submenu.ant-menu-submenu-popup .ant-menu-item {
    height: 40px;
    line-height: 40px;
}

.home-page__logout {
    display: inline;
    min-width: 80px;
    padding-right: 1em;
}

.home-page__logout button {
    color: #434656;
    text-transform: initial;
}

.home-page__main-menu {
    margin-top: 1.5em;
    padding-bottom: 160px;
}

.home-page__main-menu .anticon {
    font-size: 20px;
}

.home-page__main-menu .anticon svg path {
    fill: lightgray;
}

.home-page__main-menu svg.reacticon path {
    stroke: lightgray;
}

.home-page__main-menu .ant-menu-item:hover svg.reacticon path {
    stroke: white;
}

.home-page__main-menu .ant-menu-item:hover .anticon svg path,
.home-page__main-menu .ant-menu-submenu:hover .anticon svg path,
.home-page__main-menu .ant-menu-item.ant-menu-item-selected .anticon svg path {
    fill: white;
}

.home-page__loading {
    font-size: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home-page__version {
    color: silver;
    font-size: 75%;
    text-align: center;
    margin-top: 24px;
    width: 200px;
}

/* menu */

.ant-menu-item, .ant-menu-submenu {
    font-weight: 600;
}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title {
    margin: 0 !important;
}

.home-page__main-menu.ant-menu-inline .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
}

.home-page__main-menu.ant-menu-inline .ant-menu-sub li.ant-menu-item {
    padding-left: 54px !important;
}

/* menu - konec */

/* formulare */

.ant-form label {
    color: #434656;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .43px;
}

.ant-btn, .ant-btn-lg {
    font-weight: 600;
}

.ant-form {
    background: #fff;
    padding: 24px !important;
    padding-bottom: 12px !important;
}

.ant-form-item-label > label {
    height: 100%;
}

.actions {
    padding-bottom: 16px;
}

.ant-card-body .ant-form {
    padding: 0px;
}

/* formulare - konec */

/* tabulky */

.ant-table-thead th {
    background: #515165 !important;
    color: #fff !important;
}

/* tabulky - konec */

.home-page__toolbar .ant-divider-vertical {
    border-left: 0px;
}

.home-page__menu-button .anticon {
    font-size: 100% !important;
}

.home-page__content {
    margin: 16px;
}

.ant-menu-inline-collapsed-tooltip {
    display: none;
}

@media (max-width: 1224px) {
    .ant-layout.ant-layout-has-sider {
        position: relative;
    }

    .ant-layout-sider {
        margin-top: 60px;
        position: fixed;
        z-index: 1000;
        height: 100%;
    }

    .home-page__header {
        position: fixed;
        z-index: 1;
        width: 100%
    }

    .home-page__menu-button {
        margin: 0 16px;
    }

    .home-page__header.ant-layout-header {
        padding: 0;
    }

    .home-page__content {
        padding: 72px 16px 16px 16px;
        margin: 0;
    }
}
