.header:global(.ant-layout-header) {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 16px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.logo {
    height: 60px;
    width: 184px;
    text-align: left;
}

.logo a {
    color: #000;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
}

.logo img {
    max-height: 60px;
    max-width: 180px;
}

.search {
    width: 250px;
}

.toolbar {
    text-align: right;
    padding-left: 16px;
    height: 60px;
    overflow: hidden;
}

.toolbar :global(.ant-menu) {
    color: #434656;
    min-width: 200px;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 30px;
    border-bottom: 0px;
}

.center {
    flex: auto;
}

.toolbar :global(.ant-divider-vertical) {
    border-left: 0px;
}

.avatar {
    background: #333343;
    cursor: pointer;
    outline: 4px solid transparent;
    margin-right: 8px;
    font-size: 16px;
}

.avatar:hover {
    outline: 4px inset rgba(51, 51, 67, 0.15);
}

.avatar-popover {
    width: 240px;
}

.avatar-popover :global(.ant-popover-arrow) {
    display: none;
}

.avatar-popover :global(.ant-popover-inner-content) {
    padding: 4px;
}

.avatar-popover-header {
    padding: 12px;
}

.avatar-popover-title:not(:first-child) {
    margin-top: 8px;
}

.avatar-popover :global(ul.ant-menu) {
    margin-top: 0;
    color: #434656;
    border: none;
}

.avatar-popover :global(ul.ant-menu li:not(.ant-menu-item-divider)) {
    padding-left: 12px !important;
    line-height: 38px;
    height: 38px;
}

@media (max-width: 1224px) {
    .header {
        position: fixed;
        z-index: 1;
        width: 100%
    }

    .header:global(.ant-layout-header) {
        padding: 0;
    }
}
