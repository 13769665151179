.logo {
    height: 60px;
    width: 184px;
    text-align: left;
    display: inline-block;
    float: left;
}

.logo a {
    color: #000;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    display: inline-block;
}

.logo img {
    max-height: 60px;
    position: static;
    max-width: 180px;
}

.header:global(.ant-layout-header) {
    font-size: 14px;
    line-height: 60px;
    padding: 0 16px 0 24px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.content, .content-logged-in-user {
    text-align: center;
    margin-top: 75px;
    padding: 32px;
    background-color: white;
}

.content-logged-in-user {
    margin-top: 0;
}

.allow-button {
    background-color: green;
}

.explanation {
    padding: 8px 0;
    margin: 16px;
}

.attribute {
    margin: 8px 0;
}

.layout {
    min-height: 100vh;
}

.progress {
    margin-top: 2em;
}

@media (max-width: 1224px) {
    .content {
        margin-top: 0;
        padding: 32px 16px;
    }

    .layout {
        min-height: auto;
    }
}