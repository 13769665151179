.container {
    display: inline-block;

    border-radius: 4px;
    margin-right: 8px;
}

.container div {
    display: inline-block;
}

.item-left {
    border-radius: 4px 0 0 4px;
    background-color: #f5f5f5;
    border: 1px solid #bfbfbf;
}

.item-left :global(svg.anticon) {
    font-size: 13px !important;
}

.item-center {
    border-top: 1px solid #bfbfbf;
    border-bottom: 1px solid #bfbfbf;
}

.item-right {
    border-radius: 0 4px 4px 0;
    background-color: #f5f5f5;
    border: 1px solid #bfbfbf;
}

.item-left, .item-center, .item-right {
    padding: 0 4px;
}
