.btn-seamless,
.btn-seamless:hover,
.btn-seamless:active,
.btn-seamless:focus,
.btn-seamless:visited,
.btn-seamless .anticon-more {
    border: none !important;
    color: #434656 !important;
    opacity: 0.6 !important;
    box-shadow: none !important;
    background: transparent !important;
}

.btn-seamless:hover,
.btn-seamless:active,
.btn-seamless:focus,
.btn-seamless:visited,
.btn-seamless .anticon-more:hover {
    opacity: 1 !important;
}

.ant-btn[disabled].btn-seamless {
    background: none !important;
}

.form-section-title {
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 12px;
    border-bottom: 2px solid #bfbfbf;
}

.form-horizontal {
    margin-bottom: 24px;
    max-width: 800px;
}

.form-horizontal :global(.ant-form-item-row) {
    align-items: start;
}

.form-horizontal :global(.ant-form-item) {
    margin: 18px 0;
}

.form-horizontal :global(.ant-form-item-control-input .ant-form-item) {
    margin: 0;
}

.form-horizontal :global(.ant-switch) {
    margin-top: 6px;
}

.form-horizontal :global(.ant-checkbox-group), .form-horizontal :global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.form-horizontal :global(.ant-radio-group) {
    margin-top: 6px;
}

.form-horizontal :global(.ant-checkbox-wrapper), .form-horizontal :global(.ant-checkbox) {
    display: flex;
}

.form-horizontal :global(.ant-form-item-control) {
    min-height: auto;
}

.form-horizontal :global(.ant-form-item-control-input) {
    min-height: auto;
}

.form-horizontal :global(.ant-form-item-extra) {
    padding-top: 4px;
}

.form-horizontal :global(.ant-form-item-label) {
    padding-top: 6px;
}

.form-horizontal label {
    height: 100%;
}

.form-horizontal :global(.ant-form-item-explain) {
    padding: 4px 0 8px 0;
}

.form-horizontal :global(.ant-input), .form-horizontal :global(.ant-input-wrapper), .form-horizontal :global(.ant-input-password), .form-horizontal :global(.ant-select) {
    max-width: 400px;
}

.form-horizontal .form-buttons :global(.ant-btn) {
    margin-top: 0.5em;
    margin-left: 0.5em;
}

.form-horizontal .form-buttons :global(.ant-btn):first-child {
    margin-left: 0;
}

.list-tag {
    margin-top: 8px;
}

.list-tag :global(.ant-tag) {
    font-size: 11px;
    padding: 0 4px;
}

.heading-tag:global(.ant-tag) {
    vertical-align: middle;
    font-size: 12px;
    padding: 0 4px;
}

.column-tagged {
    display: flex;
    align-items: center;
}

.column-tagged :global(svg.anticon), .column-tagged :global(span.anticon) {
    opacity: 0.8;
    margin-left: 4px;
    font-size: 16px !important;
    color: #515165;
}

.top-button-bar {
    margin-bottom: 1em;
}

.top-button-bar :global(.ant-btn) {
    margin-left: 0.5em;
}

.top-button-bar :global(.ant-btn):first-child {
    margin-left: 0;
}

.details {
    background-color: #fff;
    max-width: 800px;
    margin-bottom: 1.5em;
}

.details :global(.ant-descriptions-item-label) {
    width: 300px;
}
