.table, .filter {
    max-width: 1200px;
}

.spacer {
    margin-top: 1.5em;
}

.list-item :global(.ant-card) {
    width: 100%;
}

.list-item div:first-child {
    margin-bottom: 2px;
}

.list-item {
    padding: 6px 0;
}

.list-item :global(.ant-card-head-title) {
    font-weight: bold;
}
