.detail {
    max-width: 800px !important;
}

.qrcode {
    margin-bottom: 1.5em;
}

.detail-box {
    padding: 12px;
}

.detail-box :global(.ant-descriptions-item-label) {
    font-weight: bold;
}

.qr-code {
    height: auto;
    margin: 32px auto;
    max-width: 256px;
    width: 100%
}

.qr-code svg {
    height: auto;
    max-width: 100%;
    width: 100%;
}

.select-option-help {
    font-style: italic;
    font-size: 90%;
    font-weight: normal;
    overflow: auto;
    white-space: normal;
}