.table {
    max-width: 600px;
}

.notifications-alert {
    margin-bottom: 1.5em;
    padding: 8px !important;
    display: inline-block;
}

.notifications-toggle-button {
    margin-right: 0.5em;
}

.notifications-toggle-button > * {
    font-size: 100%;
}

.text {
    padding: 8px 0;
    max-width: 400px;
    margin-bottom: 1em;
}