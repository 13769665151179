.select-lang {
    display: inline;
    padding-right: 10px;
}

.select-lang__language {
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    padding: 0 5px;
    height: 21px;
    line-height: 20px;
    opacity: 0.5;
}

.select-lang__language--selected {
    background: #fff;
    color: #0583ff;
    opacity: 1;
}
