.license-box th, td {
    padding: 0.2em;
    text-align: left;
}

.license-box th {
    width: 200px;
}

.dashboard :global(.ant-card) {
    margin-bottom: 16px;
}

.dashboard :global(.ant-card-body) {
    padding-top: 0;
}

.dashboard :global(.ant-card) :global(.ant-card-head) {
    background-color: white !important;
    border-bottom: initial;
    color: #434656 !important;
}

.dashboard :global(.ant-card) :global(.ant-card-head) :global(.ant-card-head-title) {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
}

.dashboard :global(.ant-card) :global(.ant-card-head) :global(.ant-select-arrow) :global(.anticon) {
    font-size: 12px !important;
}

.dashboard :global(.ant-card) :global(.ant-card-head) :global(.ant-select) {
    width: 90px;
}

.authenticators-warning {
    margin-bottom: 1em;
}

.portal-application-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;
}

.portal-application {
    display: inline-block;
    width: calc(25% - 16px);
}

.portal-application :global(.ant-card-body) {
    display: none;
}

@media (max-width: 1224px) {
    .portal-application {
        display: inline-block;
        width: 100%;
    }
}