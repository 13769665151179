.content {
    margin: 16px;
}

.details {
    max-width: none !important;
}

.details :global(.ant-descriptions-item-label) {
    width: auto !important;
}

.form {
    max-width: 100% !important;
}

.stores {
    margin-top: 1.5em;
}

.stores img {
    margin-top: 0.5em;
    max-height: 50px;
}

.stores img:first-child {
    margin-right: 0.5em;
}

.header:global(.ant-layout-header) {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px 16px;
    height: 60px;
    background: #fff;
    text-align: center;
}

.logo {
    height: 60px;
    width: 184px;
    text-align: left;
}

.logo a {
    color: #000;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
}

.logo img {
    max-height: 60px;
    max-width: 180px;
}

.search {
    width: 250px;
}


.toolbar {
    text-align: right;
    padding-left: 16px;
    height: 60px;
    overflow: hidden;
}

.toolbar :global(.ant-menu) {
    color: #434656;
    min-width: 200px;
    font-size: 14px;
    letter-spacing: .5px;
    line-height: 30px;
    border-bottom: 0px;
}

.center {
    flex: auto;
}

.toolbar :global(.ant-divider-vertical) {
    border-left: 0px;
}
