.heading {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.table, .filter, .topButtonBar {
    max-width: 1600px;
}

.columnPrincipal {
    max-width: 160px;
}

.attributeKey {
    width: 200px !important;
}

.attributeValue :global(button) {
    background: none !important;
    border: none !important;
    width: 20px !important;
    height: 20px !important;
}

.attributeValue :global(.ant-space-item) {
    margin-right: 0px !important;
}

.attributeValue :global(.audit-log-detail__attribute-value) {
    margin-left: 16px;
}

.inner-table :global(.ant-table.ant-table-small) {
    margin: 4px 8px 4px 8px !important;
}

.inner-table :global(.ant-table-title) {
    text-align: center;
}

.inner-table :global(.ant-empty-image) {
    display: none;
}

.type {
    font-weight: bold;
    margin-bottom: 8px;
}

.list {
    margin-top: 8px;
}

.list-item :global(.ant-card) {
    width: 100%;
}

.list-item {
    padding: 6px 0;
}

.list-item :global(.ant-card-head-title) {
    font-weight: bold;
}
